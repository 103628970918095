import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../stylesheets/SkillBar.css";

function SkillBar({ text, percent, changeIconStyles, gotToProjects }) {
  return (
    <div
      className="skill-item"
      onClick={() => gotToProjects(text)}
      onMouseOver={() => changeIconStyles(text)}
      onMouseLeave={() => changeIconStyles(null)}
    >
       <div className="skill-bar">
        <CircularProgressbar value={percent} text={`${percent}%`} />
      </div>
      <p className="skill-tittle">{text}</p>
     
    </div>
  );
}

export default SkillBar;
