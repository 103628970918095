import "../stylesheets/ProjectsTechsList.css";
import { useDispatch, useSelector } from "react-redux";
import technologies from "../data/skillsDatabase";
import { changeHighligtedTech } from "../redux/highligtedTechSlice";
export default function ProjectsTechsList({ moveToProjects, defaultSelected }) {
  const selectedTech = useSelector((state) => state.highligtedTech.tech);
  const dispatch = useDispatch();

  const handleShownProjects = (e) => {
    dispatch(changeHighligtedTech(e));
  };

  return (
    <div className="project-techs-list">
      <span
        className={`skill-tittle${selectedTech === "" ? " active" : ""}`}
        onClick={() => {
          handleShownProjects("");
          moveToProjects();

        }}
      >
        {defaultSelected}
      </span>
      {technologies.map((tech, index) => (
        <span
          className={`skill-tittle${
            tech.name === selectedTech ? " active" : ""}`}
          onClick={() => {
            handleShownProjects(tech.name);
            moveToProjects();
          }}
          key={index}
        >
          {tech.name}
        </span>
      ))}
    </div>
  );
}
