import { useSelector } from "react-redux";
import "../stylesheets/Project.css";
import CtaProjectButton from "./CtaProjectButton";
import Icon from "./Icon";

function Project({
  src,
  title,
  url,
  techStack = [],
  text,
  buttonText = "Ver proyecto",
}) {
  const data = useSelector((state) => state.lang.data);
  const selectedTech = useSelector((state) => state.highligtedTech.tech);
  const textLimit = 50;
  return (
    <div
      className={`project-item ${
        techStack.find((e) => e.name === selectedTech)?.name &&
        "project-item--animation"
      }`}
    >
      <figure className="project-item-figure">
        <img src={src} alt="" />
      </figure>
      <div className="project-item-content">
        <h3 title={title}>
          {title.slice(0, 20)}
          {title.length > 20 && "..."}
        </h3>
        <p title={text}>
          {text.slice(0, textLimit)}
          {text.length > textLimit && "..."}
        </p>
        {techStack.length === 1 && selectedTech !== "" ? (
          ""
        ) : (
          <h4>{data.projects.type?.text}</h4>
        )}
        <div className="project-tech-stack-container">
          {techStack.map((tech, i) => (
            <Icon
              name={tech.name}
              key={tech + i}
              src={tech.url}
              alt={tech.alt}
              item={selectedTech}
              reference="TechUsed"
            />
          ))}
        </div>
        <CtaProjectButton url={url} text={buttonText} />
      </div>
    </div>
  );
}

export default Project;
