import Project from "./Project";
import "../stylesheets/ProjectsItemsContainer.css";
import { projectsDb } from "../data/projectsDatabase";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ProjectsTechsList from "./ProjectsTechsList";

function ProjectsItemContainer({ trigger, moveToProjects }) {
  const data = useSelector((state) => state.lang.data);
  const selectedTech = useSelector((state) => state.highligtedTech.tech);
  const tag = useSelector((state) => state.lang.data.projects.defaultTag);

  const [projectsData, setProjectsData] = useState([]);
  useEffect(() => {
    selectedTech !== ""
      ? setProjectsData(
          projectsDb.filter(
            (e, i) =>
              e.techStack.filter((e) => e.name === selectedTech).flat()
                .length !== 0
          )
        )
      : setProjectsData(projectsDb);
  }, [selectedTech]);

  if (!data.projects) {
    return <section className="projects-items-container">Loading...</section>;
  }

  return (
    <>
      <ProjectsTechsList moveToProjects={moveToProjects} defaultSelected ={tag} />
      <h2 >{selectedTech !== "" ? selectedTech : tag}</h2>
      <div className="computing-projects-and-list-container">
        <div
          className={
            "projects-items-container" +
            (trigger ? " projects-items-container-animation" : "")
          }
        >
          {projectsData.map((e, i) => {
            const projectsText =
              data.projects.type.computing.projectsText.filter(
                (project) => project.name === e.name
              );
            return (
              <Project
                key={"project" + i}
                src={e.src}
                title={projectsText[0].title}
                text={projectsText[0].text}
                url={e.url}
                techStack={e.techStack}
                buttonText={e.type ==="list" ? data.projects.type.electronics.btnText : data.projects.type.computing.btnText}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default ProjectsItemContainer;
