import { useState } from "react";

import("../stylesheets/AnimatedWord.css");

export function AnimatedWord({ addContainer, words, delay }) {
  const [word, setWord] = useState([]);
  const [addingWord, setAddingWord] = useState(true);
  const [counter, setCounter] = useState(0);
  const [wordCounter, setWordCounter] = useState(0);

  const renderWord = () => {
    setTimeout(() => {

      if(addingWord === true) {
        setWord([...word, words[wordCounter][counter]]);   
        setCounter(counter + 1);
      }

      if (word.length  === words[wordCounter].length) {
        setAddingWord(false);
      }

      if (addingWord === false) {
        setWord(word.slice(0, word.length - 1));
        setCounter(counter - 1)

        if (word.length === 0) {
          setAddingWord(true);
          setWordCounter(wordCounter === words.length - 1 ? 0 : wordCounter + 1);
          setCounter(0);
        }
      }
    }, delay);

    return addContainer === true ? (
        word.join("")
      ) : (
        <NoTagElement element={words[0]} />
      )

  };

  function NoTagElement({ element }) {
    return <>{element}</>;
  }

  return <p className="hero-title animated"><span className="animated-word-container">{renderWord()}</span></p>;
}

export default AnimatedWord;
