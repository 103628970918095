import html from "../assets/icons/icons8-html-5.svg";
import css from "../assets/icons/icons8-css3.svg";
import jquery from "../assets/icons/icons8-jquery.svg";
import node from "../assets/icons/icons8-node-js.svg";
import reactIcon from "../assets/icons/icons8-react.svg";
import javaScript from "../assets/icons/icons8-javascript.svg";
import arduino from "../assets/icons/icons8-arduino.svg";
import nextJs from "../assets/icons/icons8-nextjs.svg";
import photoShop from "../assets/icons/icons8-photoshop.svg";
import blogger from "../assets/icons/icons8-blogger.svg";
import canva from "../assets/icons/icons8-canva.svg";
import visualbasic from "../assets/icons/icons8-visual-basic-96.png"
import prismaOrm from "../assets/icons/icons8-prisma-orm.svg"
import redux from "../assets/icons/icons8-redux.svg"
import tailwind from "../assets/icons/icons8-viento-de-cola-css.svg"
import bootStrap from "../assets/icons/icons8-oreja.svg"
import express from "../assets/icons/icons8-expresar-js.svg"
import mongoDb from "../assets/icons/icons8-mongo-db-96.png"
import turso from "../assets/icons/turso-logomark-aqua.png"
import cPlusPlus from "../assets/icons/icons8-c.svg"
import basic from "../assets/icons/basic.png"
import appInventor from "../assets/icons/app-inventor.png"
import proteus from "../assets/icons/proteus.png"
const technologies = [
  {
    name:"App Inventor",
    url: appInventor,
    alt: "App Inventor Icon",
    percentage: 60
  },
  {
    name: "Basic",
    url: basic,
    alt: "Basic Icon",
    percentage: 60,
  },
  {
    name: "C++",
    url: cPlusPlus,
    alt: "C++ Icon",
    percentage: 50,
  },
  {
    name: "Arduino",
    url: arduino,
    alt: "Arduino Icon",
    percentage: 90,
  },
  {
    name: "HTML",
    url: html,
    alt: "HTML Icon",
    percentage: 95,
  },
  {
    name: "CSS",
    url: css,
    alt: "CSS Icon",
    percentage: 90,
  },
  {
    name: "jQuery",
    url: jquery,
    alt: "Javascript Icon",
    percentage: 70,
  },
  {
    name: "Node Js",
    url: node,
    alt: "Node js Icon",
    percentage: 40,
  },
  {
    name: "React",
    url: reactIcon,
    alt: "React Icon",
    percentage: 70,
  },
  {
    name: "JavaScript",
    url: javaScript,
    alt: "JavaScript Icon",
    percentage: 90,
  },
  {
    name: "Nextjs",
    url: nextJs,
    alt: "Nextjs Icon",
    percentage: 50,
  },
  {
    name: "Photoshop",
    url: photoShop,
    alt: "Photoshop Icon",
    percentage: 50,
  },
  {
    name: "Blogger",
    url: blogger,
    alt: "Blogger Icon",
    percentage: 75,
  },
  {
    name: "Canva",
    url: canva,
    alt: "Canva Icon",
    percentage: 60,
  },
  {
    name: "Visual Basic",
    url: visualbasic,
    alt: "Visual Basic Icon",
    percentage: 50,
  },
  {
    name: "Prisma ORM",
    url: prismaOrm,
    alt: "Prisma ORM Icon",
    percentage: 50,
  },
  {
    name: "Redux",
    url: redux,
    alt: "Redux Icon",
    percentage: 50,
  },
  {
    name: "Tailwind",
    url: tailwind,
    alt: "Tailwind Icon",
    percentage: 60,
  },
  {
    name: "Bootstrap",
    url: bootStrap,
    alt: "Bootstrap Icon",
    percentage: 20,
  },
  {
    name: "Express",
    url: express,
    alt: "Express Icon",
    percentage: 60,
  },
  {
    name: "MongoDB",
    url: mongoDb,
    alt: "MongoDB Icon",
    percentage: 30,
  },
  {
    name: "Turso",
    url: turso,
    alt: "Turso Icon",
    percentage: 20,
  },
  {
    name: "Proteus",
    url: proteus,
    alt: "Proteus Icon",
    percentage: 70,
  }


  // <a target="_blank" href="https://icons8.com/icon/aqb9SdV9P8oC/prisma-orm">prisma-orm</a> icono de <a target="_blank" href="https://icons8.com">Icons8</a>
];

export default technologies.sort((a, b) => a.name.localeCompare(b.name));
