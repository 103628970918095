import { useSelector } from "react-redux";
import "../stylesheets/Hero.css";
import AnimatedWord from "./AnimatedWords";
import { PiReadCvLogoFill } from "react-icons/pi";


function Hero({ className, trigger }) {
  const data = useSelector((state) => state.lang.data);

  if (!data.hero) {
    return (
      <section className={className} id="hero">
        Loading...
      </section>
    );
  }

  return (
    <section className={className}>
      <div className="hero-content container">
        <div>
        <p className="hero-title">
          {data.hero.title} 
        </p>
         {trigger ? (
            <AnimatedWord
              addContainer={true}
              words={data.hero.words}
              delay={100}
            />
          ) : (
            ""
          )}
        </div>    
        
        <p className="hero-paragraph">{data.hero.paragraph}</p>
        <div className="hero-cta-buttons-container">
          <a className="hero-cta" href="#about">
            {data.hero.ctaButton}
          </a>
        </div>
      </div>
      <a
            className="nav-cta-cv"
            href={data.cv?.file}
            target="_blank"
            rel="noreferrer"
          >
            {data.cv?.text}
            <PiReadCvLogoFill />
          </a>
    </section>
  );
}

export default Hero;
